html, body, #root {
	width: 100vw;
	height: 100vh;
}

body {
	font-family: system-ui;
	font-size: 16px;
}

h1 { font-size: 2rem; font-weight: bold; margin: 0 0 4rem; }
h2 { font-size: 1.4rem; font-weight: bold; margin: 0; }
h3 { font-size: 1rem; font-weight: bold; margin: 0; }
h4 { font-size: 1rem; font-style: italic; margin: 0; }
